<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table">
          <thead>
            <tr>
              <th>Portfolio Details</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="portfolioRules">
        <b-form>
          <b-row class="mt-2">
            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group>
                <label for="title">Project Name</label>
                <validation-provider name="Project Name" #default="{ errors }" rules="required">
                  <b-form-input id="title" v-model="portfolioData.title" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group>
                <label for="title">Project Link</label>
                <validation-provider name="Project Link" #default="{ errors }" rules="required">
                  <b-form-input id="link" v-model="portfolioData.link" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label for="team">Team</label>
                <validation-provider name="team" v-slot="{ errors }" rules="required">
                  <v-select v-model="portfolioData.team" :options="teamOptions" :reduce="(option) => option.value" :placeholder="'Select Team'" :is-clearable="true" :is-invalid="errors.length > 0"></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <div>
                <vue-editor v-model="portfolioData.description" :editor-toolbar="customToolbar" />
              </div>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group>
                <label for="images">Upload Images</label>
                <b-form-file name="images" v-model="files" ref="imageInput" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." multiple @change="handleFileUpload" />
              </b-form-group>
            </b-col>

            <div class="preview-container">
              <div v-for="(file, index) in portfolioData.images" :key="index" class="file-preview" v-show="file.type != 'delete'">
                <img v-if="file.type == 'new'" :src="file.url" :alt="file.name" class="file-image" />
                <img v-else :src="portfolioFileURL + file.url" :alt="file.name" class="file-image" />
                <button type="button" @click="removeFile(index)" class="remove-button">X</button>
              </div>
            </div>

            <b-col cols="12" class="mt-2">
              <b-button v-if="$router.currentRoute.params.id == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BForm, BCard, BContainer, BFormFile, BFormTextarea, BButton, BOverlay, BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import portfolioStoreModule from '../portfolioStoreModule';
import store from '@/store';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { required, email } from '@validations';
import router from '@/router';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    BCard,
    BForm,
    BContainer,
    BFormFile,

    BFormTextarea,

    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VueEditor,
    vSelect,

    ToastificationContent,
  },
  directives: {
    Ripple,
  },

  setup() {
    const PORTFOLIO_APP_STORE_MODULE_NAME = 'portfolio';
    // Register module
    if (!store.hasModule(PORTFOLIO_APP_STORE_MODULE_NAME)) store.registerModule(PORTFOLIO_APP_STORE_MODULE_NAME, portfolioStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PORTFOLIO_APP_STORE_MODULE_NAME)) store.unregisterModule(PORTFOLIO_APP_STORE_MODULE_NAME);
    });
  },

  computed: {},

  created() {
    this.getByIdPortfolio();
  },

  data() {
    return {
      id: null,
      formShow: false,
      portfolioData: {
        title: null,
        link: null,
        description: null,
        team: null,
        images: [],
      },
      required,
      email,
      files: [],
      teamOptions: [
        { value: 'Excel', label: 'Excel' },
        { value: 'Web', label: 'Web' },
      ],

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'video'],
        ['clean'], // remove formatting button
      ],

      portfolioFileURL: store.state.app.portfolioFileURL,

      // customToolbar: [
      //   ['bold', 'italic', 'underline'],
      //   [{ list: 'ordered' }, { list: 'bullet' }],
      //   ['image', 'code-block'],
      // ],
    };
  },

  methods: {
    formSubmitted() {
      this.formShow = true;

      this.$refs.portfolioRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store
              .dispatch('portfolio/updatePortfolio', this.portfolioData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Portfolio Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                router.push({
                  name: 'portfolio-list',
                });
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    text: 'Please try again or report an issue to support',
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                });
                // router.push({ name: 'quote-list' });
              });
          } else {
            store
              .dispatch('portfolio/savePortfolio', this.portfolioData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Portfolio Insert Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                router.push({
                  name: 'portfolio-list',
                });
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    text: 'Please try again or report an issue to support',
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                });
                // router.push({ name: 'quote-list' });
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },

    getByIdPortfolio() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        store
          .dispatch('portfolio/getPortfolioById', { id: router.currentRoute.params.id })
          .then((response) => {
            response.data.images.forEach((element) => {
              element.type = 'old';
            });

            this.portfolioData = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            // router.push({ name: 'quote-list' });
          });
      } else {
        this.formShow = false;
      }
    },

    handleFileUpload(e) {
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = (e) => {
          this.portfolioData.images.push({ name: file.name, url: e.target.result, type: 'new' });
        };

        reader.readAsDataURL(file);
      }

      this.files = [];
    },
    removeFile(index) {
      if (this.portfolioData.images[index].type == 'new') {
        this.portfolioData.images.splice(index, 1);
      } else {
        this.portfolioData.images[index].type = 'delete';
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.preview-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}

.file-preview {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.file-image {
  width: 150px;
  height: 90px;
  border: 1px solid #ccc;
}

.remove-button {
  position: absolute;
  top: 5px; /* Düğmenin yatay pozisyonunu ayarlayın */
  right: 5px; /* Düğmenin dikey pozisyonunu ayarlayın */
  background-color: transparent; /* Arkaplanı temizleyin */
  border: none; /* Kenarlığı kaldırın */
  color: white; /* Düğme rengini ayarlayın */
  background-color: red;
  font-size: 16px; /* Yazı tipi boyutunu ayarlayın */
  cursor: pointer; /* İşaretçiyi işaretçi yapın */
  opacity: 0; /* Başlangıçta görünmez yapın */
  transition: opacity 0.2s; /* Geçiş efektini ayarlayın */
}

.file-preview:hover .remove-button {
  opacity: 1; /* Resmin üzerine gelince düğmeyi görünür yapın */
}
</style>
